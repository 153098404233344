import React from 'react'
import WebSite from './website/website';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <WebSite/>
  );
}

export default App;

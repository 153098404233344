export const VERSION_NUMBER = '1.0';
export const MORTGAGE_CALCULATOR = 'Mortgage Calculator';
export const PRINCIPAL = 'Principal';
export const ANNUAL_INTEREST_RATE = 'Annual Interest Rate (%)';
export const YEARS_TO_PAYOFF = 'Years To Payoff';
export const EXTRA_AMOUNT_OF_PAYMENT_PER_MONTH = 'Extra Payment Amount Per Month';
export const CALCULATE = 'Calculate';
export const TOTAL_INTEREST_WITHOUT_EXTRA = 'Total Interest Without Extra Payment: $';
export const TOTAL_INTEREST_WITH_EXTRA = 'Total Interest With Extra Payment: $';
export const TOTAL_SAVINGS = 'Total Savings: $';
export const MONTH = 'In Months';
export const YEAR = 'In Years';
export const STANDARD_MONTHLY_PAYMENT = 'Standard Monthly Payment';
export const STANDARD_PRINCIPAL_PAYMENT = 'Standard Principal Payment';
export const STANDARD_INTEREST_PAYMENT = 'Standard Interest Payment';
export const STANDARD_REMAINING_BALANCE = 'Standard Remaining Balance';
export const EXTRA_MONTHLY_PAYMENT = 'Extra Monthly Payment';
export const EXTRA_PRINCIPAL_PAYMENT = 'Extra Principal Payment';
export const EXTRA_INTEREST_PAYMENT = 'Extra Interest Payment';
export const EXTRA_REMAINING_BALANCE = 'Extra Remaining Balance';
export const PAYMENT_DIFFERENCE = 'Payment Difference';
export const PRINCIPAL_DIFFERENCE = 'Principal Difference (Stnd vs. Ext)';
export const INTEREST_DIFFERENCE = 'Interest Difference';
export const REMAINING_BALANCE_DIFFERENCE = 'Remaining Balance Difference';
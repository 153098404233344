import React from "react";
import MortgageCalculator from "../components/mortgageCalculator";

function MortgageCalculatorComponent(props) {
  return (
    <MortgageCalculator {...props} />
  );
}

export default MortgageCalculatorComponent;

import React from "react";
import TaskManager from "../components/taskManager.js";

function TaskManagerComponent(props) {
  return (
    <TaskManager {...props} />
  );
}

export default TaskManagerComponent;
